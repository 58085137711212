@import "github.css";

$primary: #173148;
$secondary: #f68712;

@font-face {
  font-family: "PT Sans";
  src: url("../../assets/fonts/PTSans-Regular.ttf");
}
@font-face {
  font-family: "PT Sans";
  src: url("../../assets/fonts/PTSans-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "PT Sans";
  src: url("../../assets/fonts/PTSans-Italic.ttf");
  font-style: italic;
}
@font-face {
  font-family: "PT Sans";
  src: url("../../assets/fonts/PTSans-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}

html,
body {
  background-color: white;
  font-family: 'PT Sans', Verdana, Arial, sans-serif;
  color: $primary;
  margin: 0;
  padding: 0;
  font-size: 16px;
}
h1, h2 {
  margin: 0;
}

header,
nav,
section,
footer {
  padding: 0 2em;
  margin: 0 auto;
  clear: both;
  position: relative;
}
header,
footer {
  background-color: $primary;
  box-shadow: 0px 0px 5px 0px $primary;
}
header {
  padding: 2em 2em 1em;
}
footer {
  padding: 2em 2em;
  margin: 1em 0;
}

header > div,
nav > div,
section > div,
footer > div {
  max-width: 55em;
  min-width: 200px;
  margin: 0 auto;
}

header {
  h1,
  h2 {
    font-weight: normal;
    position: relative;
  }
  h1 {
    color: white;
    line-height: 100%;
    margin-bottom: 0.1em;
    font-size: 4em;
  }
  h1:before {
    content: " ";
    position: absolute;
    left: -100%;
    margin-left: -0.2em;
    top: 0.0em;
    width: 100%;
    height: 1.2em;
    background-color: $secondary;
  }
  h2,
  h2:after {
    color: $secondary;
    font-size: 1.5em;
    padding-bottom: 0.75em;
  }
}

footer {
  color: white;

  a {
    color: white;
  }

  img {
    float: left;
    width: 6em;
  }
  span {
    display: block;
    margin-left: 7em;
  }
  span.ln {
    margin-top: 1em;
  }
}

nav {
  ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0;
  }
  ul li {
    list-style-type: none;
    flex-grow: 1;
    flex-basis: 1em;
    text-align: center;
  }
  ul li a {
    display: block;
    margin: 0.1em 0.2em;
    padding: 0.5em;
    background-color: $primary;
    text-decoration: none;
    color: white;
  }
  ul li a:hover,
  ul li a.active {
    background-color: $secondary;
    color: $primary;
  }
}

section {
  h1,
  h2,
  h3 {
    font-weight: normal;
    margin: 1.3em 0 -0.2em;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.7em;
  }
  h3 {
    font-size: 1.5em;
  }
  a {
    color: $primary;
  }
  a:hover {
    color: scale-color($primary, $lightness: 20%);
  }

  &.exercises {
    img {
      max-width: 100%;
    }
  }
}

.bookcover img {
  max-width: 10em;
  float: right;
  box-shadow: 0 0 5px 0 $primary;
  border: 0.5em solid white;
  margin: 0 0em 2em 2em;
}

// mobile layout
@media screen and (max-width: 550px) {
  header {
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.3em;
    }
  }
  section {
    h1 {
      font-size: 1.7em;
    }
    h2 {
      font-size: 1.4em;
    }
    h3 {
      font-size: 1.1em;
    }
  }
  nav ul {
    flex-direction: column;
  }
}

/*----new in jekyll website----*/

.more-info {
  margin: 10em 0 1em;
}
